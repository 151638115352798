<template>
    <section class="home">

        <web-header active="/" />

        <section class="banner">
            <div class="content">
                <div class="text">
                    <h1>一家专注于互联网的公司</h1>
                    <p>构建集开发、运维、运营、数据分析于一体的一站式的互联网公司。多年技术实力，助您打造最符合您企业互联网系统。</p>
                </div>
            </div>
            <div class="vanta" ref='vanta'></div>
        </section>

        <footer>©2018-2023 山东多源网络科技有限公司 <a style="padding:0 10px" href="http://www.beian.miit.gov.cn">鲁ICP备18029286号</a> <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37149202001046"><img src="../../assets/images/j.png" />鲁公网安备37149202001046号</a></footer>

        
        <!-- 
        <div class="content">

            <div class="item">
                <div class="text">
                    <label>我们可以</label>
                    <h2>让传统企业互联网化</h2>
                    <div class="desc">
                        <p>再小的个体也有自己的品牌</p>
                        <p>再小的企业也能实现网上办公</p>
                    </div>
                </div>
                <div class="image">
                    <div class="mobile">
                        <img src="https://wximg.qq.com/wxp/home-site/product-preview/PD.gif" />
                    </div>
                </div>
            </div>


            <div class="item">
                <div class="image">
                    <div class="mobile">
                        <img src="https://wximg.qq.com/wxp/home-site/product-preview/PB.gif" />
                    </div>
                </div>
                <div class="text">
                    <label>我们可以</label>
                    <h2>让活动在微信上传播</h2>
                    <div class="desc">
                        <p>再小的个体也有自己的品牌</p>
                        <p>再小的企业也能实现网上办公</p>
                    </div>
                </div>
            </div>



        </div>
        


        <Footer></Footer>
         -->


    </section>
</template>

<style lang="scss" scoped>
.home {
    .banner {
        min-width: 980px;
        overflow: hidden;
        margin-top: -80px;
        position: relative;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .vanta{
            position: absolute;
            width: 100%;
            height: 100vh;
            z-index: 1;
        }
        .content{
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            max-width: 1200px;
            margin: 0 auto;
            .text{
                padding-right: 100px;
                padding-right: 600px;
                h1{
                    color: #fff;
                    font-size: 40px;
                }
                p{
                    color: rgba(255, 255, 255, 0.9);
                    font-size: 18px;
                }
            }
            
        }
    }

    footer{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 30px;
        font-size: 13px;
        z-index: 9;
        color: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        a{
            color: rgba(255, 255, 255, 0.5);
            display: inline-flex;
            align-items: center;
            img{
                width: 20px;
                vertical-align: top;
            }

        }
    }
}

</style>


<script>
import WebHeader from '../../components/web-header.vue'
import * as THREE from 'three';
import RINGS from 'vanta/dist/vanta.rings.min'
export default {
    name: "home",
    components: {WebHeader},
    mounted(){
        RINGS({
            el: this.$refs.vanta,
            THREE: THREE,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 0.8,
            scaleMobile: 1.00,
            backgroundColor: 0x121212,
            color: 0x1777FF
        })

        // GLOBE({
        //     el: this.$refs.vanta,
        //     THREE: THREE,
        //     mouseControls: true,
        //     touchControls: true,
        //     gyroControls: false,
        //     minHeight: 200.00,
        //     minWidth: 200.00,
        //     scale: 1.00,
        //     scaleMobile: 1.00,
        //     backgroundColor: 0x121212,
        //     color: 0xffffff,
        //     color2: 0x1777FF,
        //     size: 0.8
        // })

    }
};
</script>